<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Editar máquina
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editMachine">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="8">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group
                  :label="'Nombre'"
                  label-for="project-name"
                >
                  <b-form-input
                    v-model="machine.name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="8">
              <b-form-group
                :label="'Descripción'"
                label-for="department-description"
              >
                <quill-editor
                  v-model="machine.description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-checkbox
                v-model="machine.active"
                value="1"
              >
                Activo
              </b-form-checkbox>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ 'Guardar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      projectPrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      machine: 'machines/getMachine',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'machines/edit',
      getMachine: 'machines/getMachine',
    }),
    handleSubmit() {
      this.$refs.editMachine.validate().then(success => {
        if (success) {
          const { id } = this.machine
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, machine: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()

      data.append('name', this.machine.name)
      console.log(this.machine.description)
      if (this.machine.description) {
        data.append('description', this.machine.description)
      }
      data.append('active', this.machine.active)

      return data
    },
  },
  async created() {
    await this.getMachine(this.$route.params.id)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
